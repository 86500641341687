import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faArrowUp, faAddressCard } from '@fortawesome/free-solid-svg-icons'

import qrCode from '../images/qrcode_local.png';


function Contact() {
    return (
      <section id="Contact">
          <header>
            <h1 className="sectionheader">Contact</h1>
            <hr className="sectionRuler" />
          </header>

          <Container>
            <Row className="rowmargin">
              <Col></Col>
              <Col lg={4}>
              <p>
                Dr. Wolfram Anders<br />
                Milanstraße 12<br />
                D-71726 Benningen am Neckar</p>
                <p><FontAwesomeIcon size="1x" icon={faPhone} /> +49 176 47136385<br />
                <FontAwesomeIcon size="1x" icon={faEnvelope} /> info@wolfram-anders-consulting.de</p>
              </Col>
              <Col lg={4}>
                  <Figure>
                    <Figure.Image
                      width={250}
                      height={250}
                      alt="QR Code"
                      src={qrCode}
                    />
                    <Figure.Caption>
                      Scan for contact information
                    </Figure.Caption>
                  </Figure>
              </Col>
              <Col></Col>
            </Row>

            <Row>
            <Col></Col>
              <Col lg={8}>
                <a href="DrWolframAnders.vcf"><FontAwesomeIcon size="6x" className="highlightColor" icon={faAddressCard} alt="vCard" aria-label="vCard" /></a>
                <p>Download vCard</p>
              </Col>
              <Col></Col>
            </Row>
          </Container>




          <footer className="centered sectionfooter">
            <a href="#root"><FontAwesomeIcon className="highlightColor" size="2x" icon={faArrowUp} alt="scroll to top" aria-label="scroll to top" /></a>
          </footer>
      </section>
    );
  }
export default Contact;