import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import welcomeImage from '../images/header.jpg'
import welcomeImage2 from '../images/dose-media-bU6JyhSI6zo-unsplash.jpg'
import welcomeImage3 from '../images/trent-erwin-UgA3Xvi3SkA-unsplash.jpg'

function Welcome() {
    return (
      <section>
        <Container fluid>
          <Row>
            <Col><Image src={welcomeImage3} fluid /> </Col>
          </Row>
        </Container>
      </section>
    );
  }

export default Welcome;