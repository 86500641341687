import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp  } from '@fortawesome/free-solid-svg-icons'


import portrait from '../images/waportrait_square.jpg';
import flagGerman from '../images/Germany.png';
import flagUk from '../images/United-Kingdom.png';
import flagSpain from '../images/Spain.png';
import flagPort from '../images/Portugal.png';

function About() {
    return (
      <section id="About">
          <header>
            <h1 className="sectionheader">About</h1>
            <hr className="sectionRuler" />
          </header>

    

          <Container>
            <Row>
              <Col className="centered"><Image src={portrait} roundedCircle thumbnail  /></Col>
            </Row>
            <Row>
              <Col className="centered">
                <p>
                  Dr. Wolfram Anders<br />
                  <i>Dipl. Kaufmann (University of Stuttgart)</i>
                </p>
                <Image src={flagGerman} width="25" /> <Image src={flagUk} width="25" /> <Image src={flagSpain} width="25" /> <Image src={flagPort} width="25" />
                <p>Language skills (level: negotiable)</p>
              </Col>
            </Row>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <Row>
            <Col></Col>
              <Col xs={12} md={5} className="aboutBg halfheight">
                  <h2>My Expertise</h2>
                  <ul>
                    <li>Strategy</li>
                    <li>Structure costs of SG&A and plants</li>
                    <li>Performance- / Restructuring Program.</li>
                    <li>Shared Services</li>
                    <li>Lift and Shift</li>
                    <li>Negotiation in Purchasing and Sales, Claims Management</li>
                    <li>Interims Manager</li>
                    <li>Latin America Specialist</li>
                  </ul>
                  
              </Col>
              <Col></Col>
              <Col xs={12} md={5} className="aboutBg halfheight">
                <h2>Workstreams competencies</h2>
                <ul>
                  <li>Purchasing</li>
                  <li>Administration</li>
                  <li>Sales, Cross Selling</li>
                </ul>

                
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>&nbsp;</Col>
            </Row>
            <Row>
              <Col></Col>
              <Col lg={8}>
                <ul className='timeline'>         
                  <li>
                      <h1>1986-1987</h1>
                      <p>Einkäufer im Lichtwerk in Stuttgart - Feuerbach</p>
                  </li>
                  <li>
                      <h1>1988-1989</h1>
                      <p>Trainee Controlling im Lichtwerk in Stuttgart - Feuerbach</p>
                  </li>
                  <li>
                      <h1>1988-1992</h1>
                      <p>Berufsbegleitende Promotion mit dem Titel: „Strategische Einkaufsplanung“ am Lehrstuhl für allgemeine Betriebswirtschaftslehre und betriebswirtschaftliche Planung an der Universität Stuttgart </p>
                  </li>
                  <li>
                      <h1>1989-1992</h1>
                      <p>Referent im Zentraleinkauf, Stuttgart Schillerhöhe</p>
                  </li>
                  <li>
                      <h1>1992-1996</h1>
                      <p>Leiter der Abteilung Einkauf der Werke Hildesheim und Göttingen</p>
                  </li>
                  <li>
                      <h1>1996-1997</h1>
                      <p>Kaufmännischer Produktbereichsleiter für Öffentliche Netze in Backnang im Geschäftsbereich Bosch Telekommunikation </p>
                  </li>
                  <li>
                      <h1>1997-2001</h1>
                      <p>Kaufmännischer Werkleiter in Treto, bei Santander, Nord-Spanien (Fertigung von Startern und Generatoren)</p>
                  </li>
                  <li>
                      <h1>2001-2006</h1>
                      <p>Hauptreferent in der Zentralabteilung Controlling, Planung und Mergers & Acquisitions, Stuttgart Schillerhöhe</p>
                  </li>
                  <li>
                      <h1>2006-2011</h1>
                      <p>Kaufmännischer Werkleiter des Werkes Ansbach (Fertigung von Elektronischen Steuergeräten für ABS-, ESP- und Airbag Systeme), sowie Produktteamleiter Airbag<br/>Ehrenamtlich: Vorstandsmitglied VBM/BAYME</p>
                  </li>
                  <li>
                      <h1>2012-2019</h1>
                      <p>Kaufmännischer Geschäftsleiter Bosch Lateinamerika (CFO) <br/>Ehrenamtlich: Vice-, 4 Jahre Präsident AHK Sao Paulo</p>
                  </li>
                  <li>
                      <h1>2019-2020</h1>
                      <p>Leitung Bosch Automotive Performance Programm bis 12.2020.<br />Ehrenamtlich: Vorstandsmitglied Primavera e.V. (Hilfe für Kinder in Not)</p>
                  </li>
                  <li>
                      <h1>Jan.-Sep. 2021</h1>
                      <p>Interims CFO of Bosch IT Division CI</p>
                  </li>
                  <li>
                      <h1>Nov. 2021-Mar. 2022</h1>
                      <p>Negotiation Leader OEM area for Bosch</p>
                  </li>
                </ul>
              </Col>
              <Col></Col>
            </Row>           
          </Container>

          <footer className="centered sectionfooter">
            <a href="#root"><FontAwesomeIcon className="highlightColor" size="2x" icon={faArrowUp} alt="scroll to top" aria-label="scroll to top" /></a>
          </footer>

      </section>
    );
  }
export default About;