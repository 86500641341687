import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faArrowUp } from '@fortawesome/free-solid-svg-icons'


function TermsAndConditions() {
    return (
      <section id="Terms">
        <header>
          <h1 className="sectionheader">Terms and Conditions</h1>
          <hr className="sectionRuler" />
        </header>
        <Container>           
            <Row>
              <Col></Col>
              <Col xs={12} lg={8} >
                <p>Contact Person:</p>
                <p>
                Dr. Wolfram Anders<br />
                Milanstraße 12<br />
                D-71726 Benningen am Neckar</p>
                <p><FontAwesomeIcon size="1x" icon={faPhone} /> +49 176 47136385<br />
                <FontAwesomeIcon size="1x" icon={faEnvelope} /> info@wolfram-anders-consulting.de</p>
                <p>Referring information obligations § 5 Abs. 1 TMG and § 5 Abs. 1 Nr. 2 TMG</p>
              </Col>
              <Col></Col>
            </Row>
          </Container>

          <footer className="centered sectionfooter">
            <a href="#root"><FontAwesomeIcon className="highlightColor" size="2x" icon={faArrowUp} alt="scroll to top" aria-label="scroll to top" /></a>
          </footer>
      </section>
    );
  }
export default TermsAndConditions;