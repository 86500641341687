import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faGem, faRocket, faArrowUp } from '@fortawesome/free-solid-svg-icons'


function Company() {
    return (
      <section>


          <Container>           
            <Row>
            <Col></Col>
              <Col xs={12} md={3} className="visionBg fullheight">
                  <h2>Vision</h2>
                  <p>Increasing performance of my Customer to secure future business sustainable.</p>
                  <FontAwesomeIcon size="6x" icon={faEye} className="align-bottom" />
              </Col>
              <Col></Col>
              <Col xs={12} md={3} className="missionBg fullheight">
                <h2>Mission</h2>
                <p>Improving structure costs of SG&A and plants with high expertise, profound experience and proven tools to strengthen Customers competitiveness and rising profitability. Analyzing barriers and helping to remove them. Support in important Workstreams like Purchasing.</p>
                <FontAwesomeIcon size="6x" icon={faRocket} className="align-bottom" />
              </Col>
              <Col></Col>
              <Col xs={12} md={3} className="valuesBg fullheight">
                <h2>Values</h2>
                <p>Fairness, Responsibility, Liability and Legality, Initiative and Consequence, Openess and Trust.</p>
                <FontAwesomeIcon size="6x" icon={faGem} className="align-bottom" />
              </Col>
              <Col></Col>
            </Row>
            
          </Container>

          <footer className="centered sectionfooter">
            <a href="#root"><FontAwesomeIcon className="highlightColor" size="2x" icon={faArrowUp} alt="scroll to top" aria-label="scroll to top" /></a>
          </footer>

      </section>
    );
  }
export default Company;