import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image'
import Bildmarke from '../images/Bildmarke.png';


function Navigation() {
    return (
      <div>
          <Navbar expand="lg">
            <Navbar.Brand href="/">
              <Image src={Bildmarke} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="/">Home</Nav.Link>                
                <p className="nav-link xmhidden" >|</p>
                <Nav.Link href="index.html#About">About</Nav.Link>
                <p className="nav-link xmhidden" >|</p>
                <Nav.Link href="index.html#Contact">Contact</Nav.Link>
                <p className="nav-link xmhidden" >|</p>
                <Nav.Link href="index.html#Terms">Terms and Conditions</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
      </div>
    );
  }
export default Navigation;