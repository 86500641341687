function Footer() {

    return (
        <section id="Footer">
            <header>
                <hr />
            </header>
            <p className="centered">
                <p>&copy; 2022 Wolfram Anders Consulting</p>
            </p>
        </section>
    );
  }

export default Footer;