import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navigation from './pages/Navigation.js'
import Welcome from './pages/Welcome.js'
import VisionMission from './pages/VisionMission.js'
import About from './pages/About.js'
import Contact from './pages/Contact.js'
import Footer from './pages/Footer.js'
import TermsAndConditions from './pages/TermsAndConditions.js'


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { faLowVision } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <Router>
    <Container>

      <Row>
        <Col>
            <Navigation />
        </Col>
      </Row>

      <Switch>

          <Route path="/">
            <Row className="rowpadding">
              <Col>
                  <Welcome />
              </Col>
            </Row>
            <Row className="rowpadding">
              <Col>
                  <VisionMission />
              </Col>
            </Row>
            <Row className="rowpadding">
              <Col>
                  <About />
              </Col>
            </Row>
            <Row className="rowpadding">
              <Col>
                  <Contact />
              </Col>
            </Row>
            <Row className="rowpadding">
              <Col>
                  <TermsAndConditions />
              </Col>
            </Row>
            
          </Route>

      </Switch>

      <Row className="rowpadding">
              <Col>
                  <Footer />
              </Col>
          </Row>
    </Container>
    </Router>
  );
}

export default App;
